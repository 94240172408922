.webtec {
    width: 100%;
    background: var(--color-pri);
    // transform: scaleX(-1);

    .inner {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        // transform: scaleX(-1);

        .rightside {
            width: 100%;
            // padding: 100px 0px;


            p {
                padding-right: 30%;
                width: 310px;
                font-size: 40px;
                font-weight: 600;
                color: white;
                line-height: 1.2em;
                margin: 0;
                margin-left: auto;


                span {
                    color: var(--color-sec);
                }
            }
        }

        .leftside {
            color: white;
            padding: 100px 0px;
            padding-left: 10%;

            .catsec {
                width: 415px;
                margin-bottom: 50px;
                margin: 0 auto;

                p {
                    margin: 0px;
                    font-size: 18px;
                }

                img {
                    width: 50px;
                }

                .hedi {
                    color: var(--color-sec);
                    font-weight: 600;
                    margin-top: 15px;
                    margin-bottom: 5px;
                    font-size: 20px;
                }
            }
        }
    }
}