.ourexpertise {
  width: 100%;
  padding: 50px 0px;
  padding-bottom: 100px;

  .inner {
    width: 1300px;
    height: 100%;
    margin: 0 auto;

    .text {
      text-align: center;

      h3 {
        color: var(--color-pri);
      }

      p {
        margin: 0px;
        width: 850px;
        font-size: 18px;
        margin: 0 auto;
        // line-height: 1.3em;
      }
    }

    .mapdata {
      display: grid;
      grid-template-columns: repeat(3, minmax(400px, 400px));
      gap: 2rem;
      justify-content: center;
      margin: 0 auto;
      margin-top: 50px;

      .box {
        display: grid;
        grid-template-rows: 70px auto auto;
        align-items: center;
        justify-content: center;
        padding: 50px 20px;
        border-radius: var(--border-radius);
        box-shadow: 0 0 6px 1px rgb(223, 222, 222);
        height: auto;
        transition: all 0.25s ease;
        .icon {
          width: 60px;
          height: 60px;
          svg#Layer_1 {
            fill: var(--color-pri);
          }
        }
        h4 {
          color: var(--color-pri);
        }
      
        &:hover {
          background: var(--color-pri);
          .icon {
            svg#Layer_1 {
              fill: white !important;
            }
          }
          h4 {
            color: white;
          }
          p {
            color: white;
          }
        }
        // even
        &:nth-child(even) {
          background: linear-gradient(
            160deg,
            rgba(67, 109, 180, 1) 0%,
            rgba(9, 27, 63, 1) 100%
          );
          .icon {
            width: 60px;
            height: 60px;
            svg#Layer_1 {
              fill: white;
            }
          }
          h4 {
            color: white;
          }
          p {
            color: white;
          }
          &:hover {
            background: var(--color-pri);
            .icon {
              svg#Layer_1 {
                fill: white !important;
              }
            }
            h4 {
              color: white;
            }
            p {
              color: white;
            }
          }
        }

        .imgsec {
          display: grid;
          width: 55px;
          height: 55px;
          // background: var(--color-pri);
          justify-content: center;
          align-items: center;
          border-radius: var(--border-radius);
          margin-bottom: 20px;

          img {
            width: 55px;
          }
        }

        h3 {
          color: var(--color-pri);
          font-size: 24px;
          margin: 0px;
          line-height: 1.2em;
        }

        p {
          font-size: 18px;
          margin: 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1550px) {
  .ourexpertise .inner {
    width: 80%;
  }
  .ourexpertise .inner .mapdata {
    grid-template-columns: repeat(3, 1fr);
  }
  .ourexpertise .inner .mapdata .box h4 {
    line-height: 1.1em;
    font-size: 20px !important;
  }

  .ourexpertise .inner .mapdata .box:nth-child(even) h4 {
    line-height: 1.1em;
    font-size: 20px !important;
  }
  .ourexpertise .inner .mapdata .box:nth-child(even) {
    gap: 0.5rem;
  }
  .ourexpertise .inner .mapdata .box {
    gap: 0.5rem;
    grid-template-rows: 70px 20px auto;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 1085px) {
  .ourexpertise .inner .mapdata {
    grid-template-columns: repeat(2, 1fr);
  }
  .ourexpertise .inner .text p {
    width: 100%;
  }
}

@media only screen and (max-width: 717px) {
  .ourexpertise .inner .mapdata {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 500px) {
  .ourexpertise .inner {
    width: 90%;
  }
  .ourexpertise .inner .mapdata .box {
    padding: 30px 20px;
  }
}
