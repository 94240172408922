.home {
  width: 100%;
}

@media only screen and (max-width: 968px) {
  .Contactformcont .contactforminner {
    border-radius: 20px;
  }

  .wrap .Footerv1 .Footerinner .v1remote {
    padding-top: 50px;
  }
}
