.Homeportfolio {
  width: 100%;
  position: relative;
  background: #f0f6ff;

  .btnwrapper {
    margin: 0 auto;
    display: grid;
    justify-content: center;
    padding: 50px 0px;

    .button {
      margin: 0 auto;
      width: 130px;

      a {
        text-decoration: none;
        color: var(--color-pri);
      }
    }
  }

  .topinner {
    width: 1300px;
    margin: 0 auto;
    text-align: center;
    padding: 60px 0px;
    // text-transform: capitalize;

    .h1 {
      color: var(--color-hedi);
      font-size: 36px;
    }

    p {
      width: 850px;
      margin: 0 auto;
      font-size: 18px;
    }
  }

  .portwraper {
    width: 1300px;
    margin: 0 auto;
    display: grid;
    gap: 5rem;

    .box {
      background: var(--color-pri);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      transition: all 0.4s ease;
      border-radius: var(--border-radius);
      // box-shadow: 0 0 20px 14px #767373;
      box-shadow: 0 0 20px 14px #e5e5e5;

      &:hover {
        scale: (1.03);
      }

      .letftbox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 70%;
        margin: 0 auto;

        .h1 {
          font-size: 30px;
          color: var(--color-sec);
        }

        p {
          color: white;
          font-size: 16px;
          margin: 5px 0px;
        }

        button {
          width: 120px;
          background: none;
          color: white;
          border: 1px solid white;
          border-radius: var(--border-radius);
          padding: 8px 12px;
        }

        ul {
          display: flex;
          margin: 0px;
          padding: 0px;
          margin-top: 10px;

          &:nth-last-child(2) {
            background: red;
          }

          li {
            text-decoration: none;
            list-style-type: none;
            margin-right: 10px;
            padding-right: 10px;
            color: var(--color-sec);
            font-size: 16px;
            border-right: 1px solid var(--color-sec);
            cursor: pointer;
          }

          li:last-child {
            border: none;
          }

          .blli {
            border: none;
          }
        }
      }

      .righttbox {
        display: grid;
        border-radius: var(--border-radius);

        img {
          display: grid;
          border-radius: var(--border-radius);

          height: 500px;
        }
      }
    }
  }
}

.mobportfolio {
  width: 80%;
  margin: 50px auto;
  height: auto;

  .mobinner {
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
    background: var(--color-pri);
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    .innerdata {
      text-align: center;
      padding: 30px;

      ul {
        display: flex;
        justify-content: center;
        margin: 0px;
        padding: 0px;

        &:nth-last-child(2) {
          background: red;
        }

        li {
          text-decoration: none;
          list-style-type: none;
          margin-right: 10px;
          padding-right: 10px;
          color: var(--color-sec);
          font-size: 16px;
          border-right: 1px solid var(--color-sec);
        }

        li:last-child {
          border: none;
        }

        .blli {
          border: none;
        }
      }
    }
  }
}
@media only screen and (max-width: 1500px) {
  .Homeportfolio .topinner {
    width: 80%;
  }
  .Homeportfolio .portwraper {
    width: 80%;
  }
  .Homeportfolio .topinner p {
    width: 100%;
  }
  .Homeportfolio .portwraper .box .righttbox img {
    width: 100%;
    height: auto;
  }
}
@media only screen and (max-width: 1150px) {
  .Homeportfolio .portwraper {
    width: 90%;
  }
}
@media only screen and (max-width: 999px) {
  .Homeportfolio .portwraper .box {
    grid-template-columns: 1fr;
  }
  .Homeportfolio .portwraper .box .letftbox {
    width: 90%;
    padding: 50px 0px;
  }
  .Homeportfolio .portwraper .box .righttbox img {
    width: auto;
    height: 300px;
    margin: 0 auto;
  }
  .Homeportfolio .portwraper .box .letftbox {
    text-align: center;
  }
  .Homeportfolio .portwraper .box .letftbox ul {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 500px) {
  .Homeportfolio .topinner {
    width: 90%;
    padding: 30px 0px;
  }
  .Homeportfolio .topinner p {
    font-size: 17px;
  }
  .Homeportfolio .portwraper .box .righttbox img {
    height: 280px;
    width: 100%;
  }
  .Homeportfolio .portwraper .box .letftbox {
    padding: 30px 0px;
  }
  .Homeportfolio .portwraper {
    gap: 2rem;
  }
}
