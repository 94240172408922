.liiis {
    margin-top: 100px;
    width: 100%;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .inner {
        width: 80%;
        text-align: right;
        margin: 0 auto;
        display: grid;
        align-items: center;
        height: 100%;

        .data {
            p {
                font-size: 50px;
                color: white;
                font-weight: 800;
                margin: 0px;
            }

            .color {
                color: var(--color-sec);
            }
        }
    }
}