.Businesstaxsec {
  width: 100%;
  padding: 100px 0px;
  background: var(--color-pri) !important;

  a {
    color: var(--color-pri);
    border-bottom: 2px solid var(--color-pri);
    text-decoration: none;
    font-weight: 600;
  }

  .Businesstaxsecinner {
    width: 1068px;
    height: 100%;
    margin: 0 auto;

    div {
      p {
        color: var(--color-white);
      }
    }

    .fp {
      text-align: justify;
      font-size: var(--font-size);
    }

    .fp2 {
      margin-top: 20px;
      font-size: var(--font-size);
    }

    .h1 {
      color: var(--color-sec);
      font-size: 30px;
      text-transform: capitalize;
    }

    .line {
      width: 100px;
      height: 3px;
      background: var(--color-sec);
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
      font-size: var(--font-size);
    }

    .twoseccol {
      display: grid;
      grid-template-columns: 1fr 300px;
      position: relative;
      align-items: center;

      p {
        br {
          margin-bottom: 20px;
        }
      }

      img {
        width: 250px;
        margin: 0 auto;
        position: absolute;
        right: 0px;
        bottom: 0px;
      }
    }

    .row {
      width: 100%;
      display: grid;

      .cols:last-child {
        border-bottom: 2px solid var(--color-sec);
      }

      .cols {
        color: var(--color-white);

        .sec {
          display: grid;
          grid-template-columns: 230px 1fr;
          padding: 40px 0px;
          border-top: 2px solid var(--color-sec);
          align-items: center;
          gap: 2rem;

          .fb {
            font-weight: 600;
            color: var(--color-white);
            font-size: 18px;
            width: 240px;
            display: flex;
            align-items: center;

            p {
              margin: 0px;
              line-height: 1.3em;
            }

            img {
              width: 50px;
              margin-right: 15px;
              // filter: invert(1);
            }
          }
        }
      }
    }
  }
}

.ourprocess {
  width: 100%;
  padding: 50px 0px;
  background: var(--color-pri) !important;
  color: white;

  .innerwi {
    width: 90%;
    margin: 0 auto;

    .line {
      width: 100px;
      height: 3px;
      background: var(--color-sec);
      margin-bottom: 20px;
    }
  }

  a {
    color: var(--color-pri);
    border-bottom: 2px solid var(--color-pri);
    text-decoration: none;
    font-weight: 600;
  }

  .row {
    width: 90%;
    margin: 0 auto;

    .cols:last-child {
      border-bottom: 2px solid var(--color-sec);
    }

    p {
      margin: 0px;
    }

    .sec {
      display: grid;
      grid-template-columns: 100px 1fr;
      border-top: 2px solid var(--color-sec);
      padding: 30px 0px;

      .fb {
        width: 50px;
        height: 50px;
        display: flex;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1550px) {
  .Businesstaxsec .Businesstaxsecinner {
    width: 80%;
  }
  .ourprocess .innerwi {
    width: 80%;
  }
  .ourprocess .row {
    width: 80%;
  }
}
@media only screen and (max-width: 999px) {
  .Businesstaxsec .Businesstaxsecinner .row .cols .sec {
    grid-template-columns: auto 1fr;
  }
}
@media only screen and (max-width: 550px) {
  .ourprocess .row .sec {
    grid-template-columns: 80px 1fr;
  }
  .ourprocess .innerwi {
    width: 90%;
  }
  .ourprocess .row {
    width: 90%;
  }
}
