.vission {
  align-items: center;
  width: 1300px;
  display: grid;
  grid-template-columns: 500px 500px;

  margin: 0 auto;
  margin-top: 100px;
  justify-content: space-between;
  .leftsec {
    width: 100%;

    h3 {
      color: var(--color-hedi);
      font-size: 26px;
      line-height: 1.1em;
      margin-bottom: 10px;
    }

    p {
      margin: 0px;
      margin: 0 auto;

      span {
        // color: var(--color-hedi);
        // font-weight: bold;
      }
    }
  }

  .rightsec {
    h1 {
      color: var(--color-hedi);
      width: 100%;
      font-size: 40px;
      text-align: right;
      line-height: 1.25em;

      span {
        color: var(--color-sec);
      }
    }
  }
}
@media only screen and (max-width: 1550px) {
  .vission {
    width: 90%;
  }
}

@media only screen and (max-width: 1110px) {
  .vission {
    width: 90%;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}
@media only screen and (max-width: 1080px) {
  .vission {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
  .vission .leftsec {
    order: 2;
    text-align: center;
  }
  .rightsec {
    order: 1;
  }
  .vission .rightsec h1 {
    text-align: center;
  }
  .vission .leftsec h3 {
    margin: 0px;
    margin-bottom: 0.5rem;
  }
  .vission {
    gap: 1rem;
  }
}
@media only screen and (max-width: 550px) {
  .vission {
    margin-top: 50px;
  }
  .vission .rightsec h1 {
    font-size: 26px !important;
    text-align: left;
  }
  .vission .leftsec h3 {
    font-size: 20px !important;
    text-align: left;
  }
  .vission .leftsec p {
    font-size: 17px !important;
    text-align: left;
  }
}
