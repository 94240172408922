.Magentodevelopmentservice {
  width: 100%;
  height: auto;
  .CustomSubbanner {
    img {
      width: 600px !important;
      bottom: -150px !important;
    }
    .heading {
      padding-top: 80px !important;
    }
  }
  .emergingtec {
    margin: 50px 0px;
  }
}

@media only screen and (max-width: 826px) {
  .Magentodevelopmentservice .CustomSubbanner img {
    width: 450px !important;
  }
  .Magentodevelopmentservice .CustomSubbanner .heading {
    padding-top: 70px !important;
  }
  .Magentodevelopmentservice .CustomSubbanner {
    height: 380px !important;
  }
}
@media only screen and (max-width: 500px) {
  .Magentodevelopmentservice .CustomSubbanner img {
    width: 350px !important;
    bottom: -100px !important;
  }
  .Magentodevelopmentservice .CustomSubbanner {
    margin-bottom: 180px !important;
  }
  .Magentodevelopmentservice .CustomSubbanner .heading h1 {
    font-size: 26px;
  }
}
