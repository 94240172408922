.Magentoservice {
    width: 100%;
    height: auto;
    background: var(--color-pri);
    padding: 80px 0px;

    .inner {
        width: 70%;
        height: 100%;
        margin: 0 auto;

        .centerdata {
            width: 600px;
            margin: 0 auto;
            text-align: center;

            h1 {
                color: white;

                span {
                    color: var(--color-sec);
                }
            }

            p {
                color: white;
                margin: 0px;
                font-size: 18px;
            }
        }

        .mmapdata {
            width: 100%;
            // height: 100px;
            // background-color: rebeccapurple;
            display: grid;
            grid-template-columns: repeat(2, 350px);
            color: white;
            gap: 3rem;
            margin-top: 80px;
            justify-content: center;

            .cont {
                text-align: center;

                .hedi {
                    font-size: 20px;
                    color: var(--color-sec);
                    font-weight: bold;
                }

                p {
                    font-size: 15px;
                    margin: 0px;
                    font-size: 18px;
                }
            }
        }

    }
}