.emergingtec {
    width: 100%;

    .inner {
        width: 900px;
        height: auto;
        margin: 0 auto;
        text-align: center;

        h1 {
            color: var(--color-pri);
            margin: 0px;
            font-size: 40px;
        }

        p {
            margin: 0px;
            font-size: 18px;
        }

        .listoftec {
            width: 100%;
            display: grid;
            align-items: center;
            justify-content: center;
            // margin-top: 30px;
            grid-template-columns: repeat(4, 1fr);

            .innerdiv {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 30px;

                p {
                    font-size: 18px;
                    font-weight: bold;
                    margin: 0px;
                    margin-left: 20px;
                    color: var(--color-pri);
                    padding-top: 5px;
                }

                img {
                    // margin-left: 100px;
                    height: 60px;
                }
            }

        }
    }
}