.sticky {
  position: fixed;
  top: 0;
  z-index: 9999;
}

.header {
  width: 100%;
  height: 80px;
  background: var(--color-pri);

  .inner {
    width: 1300px;
    height: 100%;
    margin: 0 auto;
    // background: red;
    display: grid;
    grid-template-columns: 200px 1fr 340px !important;

    align-items: center;
    justify-items: center;
    .btnsec {
      display: flex;
      align-items: center;
      gap: 1rem;
      .upcont {
        width: auto;
        color: white;
        height: 40px;
        background: var(--color-sec);
        border: none;
        outline: none;
        border-radius: var(--border-radius);
        color: var(--color-pri);
        font-size: 15px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        padding: 0px 10px;
        color: var(--color-pri);
        border: none;
        outline: none;
      }
      .callsec {
        a {
          margin-left: 10px;
          color: white;
          display: flex;
          align-items: center;
          text-decoration: none;
          .icon {
            margin-right: 10px;
          }
        }
      }
    }
    .logo {
      display: flex;
      justify-content: center;

      img {
        width: 80%;
      }
    }

    .navbar {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;

      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        height: 100%;
        margin: 0px;

        li {
          list-style: none;
          height: 100%;
          display: flex;
          align-items: center;

          .inactive {
            text-decoration: none;
            color: var(--color-white);
            font-size: 18px;
            margin-right: 50px;
            display: flex;
            height: 100%;
            align-items: center;

            &:hover {
              color: var(--color-sec);
              font-weight: 500;
            }
          }

          .active {
            text-decoration: none;
            font-size: 18px;
            margin-right: 50px;
            color: var(--color-sec);
            font-weight: 500;
            display: flex;
            height: 100%;
            align-items: center;
          }
        }
      }
    }
  }

  .innersub {
    box-shadow: 0 3px 7px 0 #16161626;
    width: 100%;
    height: max-content;
    position: relative;
    min-height: 500px;
    background: white;
    z-index: 9999;
    display: none;
    position: absolute;
    top: 79px;
    left: 0px;
    .inner2 {
      width: 1300px;
      height: auto;
      display: grid;
      grid-template-columns: 300px 1fr 300px;
      gap: 2rem;
      margin: 0 auto;
      padding: 50px 0px;

      p {
        font-size: 14px;
      }
      color: var(--color-pri);

      .serviceinner {
        .dis {
          display: none;
        }
        .hedi {
          font-size: 26px;
          margin: 0px;
          font-weight: 400;
          color: var(--color-pri);
          font-weight: 600;
        }
        .para {
          margin: 0px;
          margin-top: 10px;
          font-size: 16px;
          cursor: pointer;
        }
      }
      .core_services {
        .hedi {
          font-size: 26px;
          margin: 0px;
          font-weight: 400;
          color: var(--color-pri);
          font-weight: 600;
        }
        .wraper {
          margin-top: 10px;
          cursor: pointer;
          h3 {
            color: var(--color-pri);
            font-size: 18px;
            font-size: 500;
          }
          p {
            margin: 0px;
          }
        }
      }
      .our_servicer {
        .hedi {
          font-size: 26px;
          margin: 0px;
          font-weight: 400;
          color: var(--color-pri);
          font-weight: 600;
        }
        .links {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 1rem;
          margin-top: 10px;
          .webdev {
            .devi {
              margin-bottom: 15px;
              cursor: pointer;
              a {
                text-decoration: none;
                color: var(--color-pri);

                span {
                  text-decoration: none;
                  display: block;
                  font-size: 18px;
                  font-weight: 600;
                }
                p {
                  font-size: 14px;
                  margin: 0px;
                }
              }
            }
          }
          .socialmedia {
            .devi {
              margin-bottom: 15px;
              cursor: pointer;
              a {
                text-decoration: none;
                color: var(--color-pri);

                span {
                  text-decoration: none;
                  display: block;
                  font-size: 18px;
                  font-weight: 600;
                }
                p {
                  font-size: 14px;
                  margin: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.mobilenav {
  width: 100%;
  height: 80px;
  position: relative;
  margin: 0px;
  padding: 0px;
  transition: all 1s ease;

  .mobheaderupper {
    width: 100%;
    height: 80px;
    // background: var(--color-pri);
    background: white;
    display: grid;
    grid-template-columns: 80px 1fr;
    justify-content: content;
    align-items: center;

    .ham {
      display: grid;
      justify-content: center;
      align-items: center;
      font-size: 22px;
    }

    .moblogo {
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateX(-30px);
      img {
        height: 40px;
      }
    }
  }

  .drower {
    width: 100%;
    min-height: 100vh;
    max-height: auto;
    position: absolute;
    z-index: 9999;
    background: var(--color-pri);
    top: 80px;
    transition: all 1s ease;

    .mobilenavli {
      margin: 0px;
      padding: 0px;
      list-style-type: none;
      padding: 50px;

      li {
        margin-bottom: 10px;
        text-decoration: none;
        color: white;
        font-weight: 500;
        .subnav {
          padding-left: 20px;
        }
        .inlinearr {
          display: grid;
          grid-template-columns: 1fr 50px;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          .iconweb {
            color: white;
            display: flex;
            justify-self: flex-end;
            transform: rotate(180deg);
            transition: all 0.25s ease-in;
          }
          .iconwebinv {
            color: white;
            display: flex;
            justify-self: flex-end;
            transition: all 0.25s ease-in;
          }
          p {
            text-decoration: none;
            color: white;
            font-weight: 500;
            margin: 0px;
          }
        }

        a {
          text-decoration: none;
          color: white;
          font-weight: 500;
        }
        ul {
          li {
            list-style-type: none;
          }
        }
      }
    }
  }
}
.header .innersub .inner2 .core_services .wraper h3 {
  font-size: 18px !important;
}

@media only screen and (max-width: 1500px) {
  .header .inner {
    width: 80%;
    grid-template-columns: 200px 1fr 100px;
  }
  .header .inner .navbar {
    margin-left: 20px;
  }
  .header .innersub .inner2 {
    width: 100%;
    padding: 50px;
    grid-template-columns: 280px 1fr 200px;
    gap: 1rem;
  }
}

@media only screen and (max-width: 1200px) {
  .core_services.showit {
    display: none;
  }
  .header .innersub .inner2 {
    grid-template-columns: 300px 1fr;
  }

  .header .innersub .inner2 .serviceinner .dis {
    display: block;
    margin-top: 20px;
  }
  .header .innersub .inner2 {
    padding-bottom: 50px;
  }
  .header .innersub .inner2 {
    gap: 1rem;
  }
  .header .innersub .inner2 .our_servicer .links {
    justify-items: center;
  }
}
@media only screen and (max-width: 1196px) {
  .header .inner .navbar ul li .inactive {
    margin-right: 30px;
  }
  .header .inner {
    width: 90%;
  }
}
@media only screen and (max-width: 1100px) {
  .header .inner .navbar ul li .inactive {
    margin-right: 30px;
  }
  .header .inner .navbar ul li .active {
    margin-right: 30px;
  }
}
