.fwidthsec {
  width: 100%;
  margin: 10px 0px;
  height: auto;
  background: var(--color-pri);

  .fwinner {
    // width: 750px;
    width: 1068px;
    margin: 0 auto;
    color: white;
    padding: 90px 50px;
    border-radius: 15px;

    p {
      margin: 0px;
      font-size: 18px;
    }

    .hedi {
      font-size: 45px;
      font-weight: 600;
      line-height: 1.1em;
      margin: 0 0 5px;
    }

    .subhedi {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 10px;
      line-height: 1.1em;
    }

    .sec {
      p {
        font-size: 20px;
        line-height: 1.5em;
        margin-bottom: 10px;
        &:nth-last-child(1) {
          margin-bottom: 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1550px) {
  .fwidthsec .fwinner {
    width: 750px;
    padding: 90px 0px;
  }
}
@media only screen and (max-width: 1000px) {
  .fwidthsec .fwinner {
    width: 80%;
  }
}
@media only screen and (max-width: 550px) {
  .fwidthsec .fwinner {
    width: 90%;
    padding: 60px 0px;
  }
  .fwidthsec .fwinner .sec p {
    font-size: 17px;
  }
}
