.certificate {
    width: 100%;
    height: auto;
    padding: 100px 0px;

    .inner {
        width: 80%;
        margin: 0 auto;

        .uppersec {
            display: flex;
            flex-direction: column;
            align-items: center;

            h1 {
                color: var(--color-hedi);
            }

            p {
                margin: 0px;
                width: 600px;
                text-align: center;
                font-size: 18px;
            }
        }

        .slider {
            margin-top: 50px;

            .slick-track {
                display: flex;
                gap: 2rem;
                padding: 10px 0px;
            }

            .center {

                display: flex;

                .box {
                    box-shadow: 0 -1px 14px 2px #8080801c;
                    height: 280px;
                    padding: 50px 0px;
                    width: 250px;
                    display: grid;
                    // grid-gap: 3rem;
                    grid-template-rows: repeat(2, 1fr);
                    border-radius: 20px;
                    background: #fff;
                    align-items: center;

                    .image {
                        display: grid;
                        justify-content: center;
                        height: 150px;
                        align-items: center;
                        margin-bottom: 20px;

                        img {
                            width: 150px;
                        }
                    }

                    .data {
                        display: grid;
                        justify-content: center;
                        text-align: center;
                        width: 80%;
                        margin: 0 auto;

                        p {
                            margin: 0px;
                            font-size: 18px;

                            strong {
                                margin: 20px 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}