

.MobileAppDevelopment {
    width: 100%;
    height: auto;
    .fullwsec{
        margin: 50px 0px;
    }
    .emergingtec{
        margin: 50px 0px;
    }
}