.banner {
  width: 100%;
  height: 450px;
  position: relative;
  background-position: top right;
  background-size: cover;
  background-repeat: no-repeat;

  img {
    width: 100%;
    position: absolute;
    z-index: -1;
  }

  .texti {
    height: 100%;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 700px;
    margin: 0 auto;
    text-align: center;
    a {
      width: 120px;
      height: 40px;
      background: var(--color-sec);
      border: none;
      outline: none;
      border-radius: var(--border-radius);
      color: var(--color-pri);
      font-size: 15px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    p {
      margin: 0px;
      color: white;
      margin: 0px;
      margin-top: 5px;
    }

    h1 {
      color: white;
      margin: 0px;
      line-height: 1.1em;
      span {
        color: var(--color-sec);
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .banner .texti {
    width: 90%;
  }
}

@media only screen and (max-width: 484px) {
  .banner .texti h1 {
    font-size: 26px;
  }

  .banner {
    background-position: 90% 100%;
    height: 400px;
  }
}
