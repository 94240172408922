.bapp {
  width: 100%;
  padding: 50px 0px;
  background: var(--color-pri);

  .inner {
    width: 1300px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 600px);
    align-items: center;
    justify-content: space-between;

    .data {
      h2 {
        font-size: 34px;
        color: white;
        line-height: 1.1em;
        margin-bottom: 10px;

        span {
          color: var(--color-sec);
        }
      }

      p {
        color: white;
        font-size: 18px;
        // width: 80%;
        margin: 0px;
        margin-bottom: 15px;
      }
      a {
        width: 120px;
        height: 40px;
        background: var(--color-sec);
        border: none;
        outline: none;
        border-radius: var(--border-radius);
        color: var(--color-pri);
        font-size: 15px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
      }
    }

    .image {
      display: grid;
      justify-items: end;

      img {
        width: 90%;
      }
    }
  }
}

@media only screen and (max-width: 1550px) {
  .bapp .inner {
    width: 80%;
    grid-template-columns: 500px 1fr;
  }
}

@media only screen and (max-width: 1318px) {
  .bapp .inner {
    grid-template-columns: 400px 1fr;
  }
}

@media only screen and (max-width: 999px) {
  .bapp .inner {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .bapp .inner .image img {
    width: 400px;
    margin: 0 auto;
  }
  .bapp .inner .data {
    text-align: center;
  }
  .bapp .inner .data a {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 500px) {
  .bapp .inner {
    width: 90%;
  }
  .bapp .inner .data p {
    font-size: 17px;
  }
}

@media only screen and (max-width: 434px) {
  .bapp .inner .image img {
    width: 100%;
  }
}
