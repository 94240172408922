.bann {
  width: 100%;
  height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;

  .inner {
    display: grid;
    width: 70%;
    margin: 0 auto;
    align-items: center;

    .hedi {
      h5 {
        color: white;
        font-size: 50px;

        span {
          color: var(--color-sec);
        }
      }
    }
  }
}

@media only screen and (max-width: 1550px) {
  .bann {
    background-position: top right;
    height: 500px;
  }
  .bann .inner .hedi h5 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 756px) {
  .bann {
    height: 400px;
  }
  .bann .inner .hedi h5 {
    font-size: 36px;
  }
  .bann .inner .hedi h5 span {
    font-size: 36px;
  }
}
@media only screen and (max-width: 510px) {
  .bann {
    background-position: 90% 50%;
  }
}
