.custombanner {
  position: relative;
  width: 100%;
  height: 550px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 180px;
  z-index: -1;

  .heading {
    color: white;
    width: 1000px;
    margin: 0 auto;
    text-align: center;
    padding-top: 90px;
    a {
      width: 120px;
      height: 40px;
      background: var(--color-sec);
      border: none;
      outline: none;
      border-radius: var(--border-radius);
      color: var(--color-pri);
      font-size: 15px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
    .button {
      margin-top: 10px;
    }

    h2 {
      font-size: 30px;
      font-weight: 300;
      font-family: "Rubik";

      span {
        color: #ffb248;
        font-weight: 600;
      }
    }

    p {
      color: white;
      width: 80%;
      margin: 0 auto;
      font-size: 18px;
    }
  }

  img {
    bottom: -130px;
    left: 50%;
    transform: translate(-50%);
    width: 700px;
    position: absolute;
  }
}

@media only screen and (max-width: 1010px) {
  .custombanner .heading {
    width: 90%;
  }
  .custombanner img {
    width: 70%;
  }
}

@media only screen and (max-width: 872px) {
  .custombanner .heading h2 {
    font-size: 26px;
  }
  .custombanner .heading p {
    width: 100%;
    font-size: 17px;
  }
  .custombanner {
    height: 450px;
    background-position: right;
  }
  .custombanner img {
    width: 500px;
  }
}

@media only screen and (max-width: 688px) {
  .custombanner img {
    bottom: -150px;
  }
}
@media only screen and (max-width: 518px) {
  .custombanner {
    height: 400px;
    margin-bottom: 140px;
  }
  .custombanner img {
    width: 380px;
    bottom: -120px;
  }
  .custombanner .heading {
    padding-top: 60px;
  }
  .custombanner .heading h2 {
    font-size: 22px !important;
  }
  .custombanner .heading p {
    font-size: 16px;
  }
}
