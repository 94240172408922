.BlogListing {
  width: 100%;
  height: auto;
  //   background: #f3f3f3;

  .blogcont {
    padding-top: 100px;
    width: 1300px;
    height: auto;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 350px;
    gap: 3rem;
    .bloglist {
      display: block;

      .blogcontwrap {
        margin-bottom: 3rem;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        background: white;
        padding: 30px;
        display: flex;
        flex-direction: column;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 5px;
        h2 {
          color: var(--color-pri);
          // text-align: center;
          margin-bottom: 10px;
        }
        .hedidisc {
          width: 600px;
          // text-align: center;
          margin: 0 auto;
          margin-bottom: 20px;
          color: var(--color-pri);
          font-weight: 500;
          font-size: 18px;
          line-height: 1.2em;
        }
        img {
          width: 100%;
          height: auto;
        }
        .detaildesc {
          color: var(--color-pri);
          font-size: 18px;
          // text-align: center;
        }
        a {
          text-decoration: none;
          width: max-content;
          padding: 10px 20px;
          border: none;
          background: var(--color-pri);
          color: white;
          font-size: 16px;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }

    // asdasdasdsa
    .blogsidebar {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      //   background: red;
      .blogheader {
        color: white;
        padding: 10px 20px;
        background: var(--color-pri);
        p {
          font-size: 18px;
          font-weight: 600;
          margin: 0px;
        }
      }
      .blogsidebody {
        background: white;
        width: 100%;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 20px;
        button {
          background: var(--color-pri);
          color: white;
          width: 100%;
          margin-top: 10px;
          border-radius: 5px;
          padding: 5px 0px;
          font-size: 17px;
        }
        ul {
          padding: 0px;
          li {
            font-size: 18px;
            font-weight: 500;
            color: var(--color-pri);
            padding: 5px 0px;
            border-bottom: 2px solid #091b3f59;
            text-decoration: none;
            list-style-type: none;
            text-align: left;
          }
        }
        .inp {
          width: 100%;
          height: auto;
          padding: 8px 20px;
          box-sizing: border-box;
          border-radius: 5px;
          outline: none;
          border: none;
          color: var(--color-pri);
          font-size: 17px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }
      }
      .followme {
        box-sizing: border-box;
        width: 100%;
        height: auto;
        text-align: center;
        .innerfollwme {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 0.4rem;
          a {
            padding: 20px 0px;
            border-radius: 5px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            text-decoration: none;
            color: var(--color-pri);
            cursor: pointer;
            .icon {
              font-size: 20px;
            }
            p {
              margin: 0px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1550px) {
  .BlogListing .blogcont {
    width: 90%;
    grid-template-columns: 1fr 300px;
  }
}
@media only screen and (max-width: 1000px) {
  .BlogListing .blogcont .bloglist {
    order: 2;
  }
  .BlogListing .blogcont .blogsidebar {
    order: 1;
  }
  .BlogListing .blogcont {
    grid-template-columns: 1fr;
  }
  .BlogListing .blogcont .blogsidebar {
    display: grid;
    grid-template-columns: 1fr;
  }
  .BlogListing .blogcont {
    padding-top: 3rem;
  }
}
@media only screen and (max-width: 550px) {
  .BlogListing .blogcont {
    padding-top: 2rem;
  }
  .BlogListing .blogcont .blogsidebar {
    gap: 2rem;
  }
  .BlogListing .blogcont {
    gap: 2rem;
  }
  .BlogListing .blogcont .bloglist .blogcontwrap {
    padding: 20px;
  }
  .BlogListing .blogcont .bloglist .blogcontwrap h2 {
    font-size: 22px !important;
    line-height: 1.2em;
  }
  .BlogListing .blogcont .bloglist .blogcontwrap .detaildesc {
    font-size: 17px;
    line-height: 1.3em;
  }
}
