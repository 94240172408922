.Pwa {
  width: 100%;
  height: auto;
  .CustomSubbanner {
    height: 550px;
    margin-bottom: 300px !important;
    img {
      width: 500px !important;
      bottom: -250px !important;
    }
  }
  .emergingtec {
    margin: 50px 0px;
  }
}

@media only screen and (max-width: 868px) {
  .Pwa .CustomSubbanner img {
    width: 400px !important;
    bottom: -188px !important;
  }
  .Pwa .CustomSubbanner {
    margin-bottom: 230px !important;
  }
}

@media only screen and (max-width: 606px) {
  .CustomSubbanner .heading {
    padding-top: 60px !important;
  }
}

@media only screen and (max-width: 514px) {
  .Pwa .CustomSubbanner img {
    width: 350px !important;
  }
  .Pwa .CustomSubbanner {
    height: 500px !important;
  }
}

@media only screen and (max-width: 402px) {
  .Pwa .CustomSubbanner img {
    bottom: -150px !important;
    width: 300px !important;
  }
  .Pwa .CustomSubbanner {
    margin-bottom: 180px !important;
  }
}
