.leadexpertise {
  width: 100%;
  background: var(--color-back);
  .inner {
    width: 1300px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 600px 600px;
    justify-content: space-between;
    align-items: center;

    .data {
      h1 {
        font-size: 36px;
        color: var(--color-pri);
        line-height: 1.1em;
        margin-bottom: 10px;

        span {
          color: var(--color-sec);
        }
      }

      p {
        color: var(--color-pri);
        font-size: 18px;
        // width: 80%;
        margin: 0px;
        // margin-bottom: 20px;
      }
    }

    .image {
      display: grid;
      justify-content: flex-end;
      img {
      }
    }
  }
}

@media only screen and (max-width: 1505px) {
  .leadexpertise .inner {
    width: 80%;
  }
  .leadexpertise .inner {
    grid-template-columns: 500px 1fr;
  }
  .leadexpertise {
    padding-top: 50px;
  }
  // .leadexpertise .inner .image {
  //   justify-content: center;
  // }
}
@media only screen and (max-width: 999px) {
  .leadexpertise .inner {
    grid-template-columns: 1fr;
  }
  .leadexpertise .inner .image img {
    height: 400px;
  }
  .leadexpertise .inner .data {
    text-align: center;
  }
  .leadexpertise .inner .image {
    justify-content: center;
  }
}

@media only screen and (max-width: 500px) {
  .leadexpertise .inner {
    width: 90%;
  }
  .leadexpertise .inner .data p {
    font-size: 17px;
  }
  .leadexpertise .inner .data h1 {
    font-size: 30px !important;
  }
  .leadexpertise .inner .image img {
    height: 300px;
  }
}
