.cta {
  width: 100%;
  padding: 80px 0px;
  background: var(--color-pri);

  .inner {
    width: 1000px;
    height: 100%;
    margin: 0 auto;

    p {
      text-align: center;
      color: white;
      font-weight: 500;
      margin-bottom: 20px;
      line-height: 1.4em;
      font-size: 24px;
      margin: 0px;

      span {
        color: var(--color-sec);
        font-weight: bold;
      }
    }

    .buttons {
      display: flex;
      justify-content: center;
      gap: 30px;
      margin-top: 20px;
      a {
        width: 120px;
        height: 40px;
        background: var(--color-sec);
        border: none;
        outline: none;
        border-radius: var(--border-radius);
        color: var(--color-pri);
        font-size: 15px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
      }
      .custombtn {
        width: 120px;
        height: 40px;
        background: none;
        outline: none;
        border-radius: var(--border-radius);
        font-size: 15px;
        // font-weight: bold;
        color: white;
        border: 1px solid white;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 1350px) {
  .cta .inner {
    width: 700px;
  }
  .cta .inner p {
    font-size: 22px;
  }
}
@media only screen and (max-width: 999px) {
  .cta .inner p {
    font-size: 20px;
  }
  .cta .inner {
    width: 80%;
  }
}

@media only screen and (max-width: 735px) {
  .cta {
    padding: 70px 0px;
  }
}

@media only screen and (max-width: 550px) {
  .cta .inner {
    width: 90%;
  }
  .cta .inner p {
    font-size: 18px;
  }
}
