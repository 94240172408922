.CustomSubbanner {
  position: relative;
  width: 100%;
  height: 450px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .heading {
    color: white;
    width: 1300px;
    margin: 0 auto;
    text-align: center;
    padding-top: 90px;
    a {
      width: 120px;
      height: 40px;
      background: var(--color-sec);
      border: none;
      outline: none;
      border-radius: var(--border-radius);
      color: var(--color-pri);
      font-size: 15px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      margin: 0 auto;
      margin-top: 10px;
    }
    h2 {
      font-size: 30px;
      font-weight: 300;
      font-family: "Rubik";
      line-height: 1.2em;
      // margin-bottom: 10px;

      span {
        color: #ffb248;
        font-weight: 600;
      }
    }
    p {
      color: white;
      width: 80%;
      margin: 0 auto;
      margin-top: 5px;
      font-size: 18px;
    }
  }
  img {
    left: 50%;
    transform: translate(-50%);
    position: absolute;
  }
}
@media only screen and (max-width: 1293px) {
  .CustomSubbanner .heading {
    width: 90%;
  }
}
@media only screen and (max-width: 570px) {
  .CustomSubbanner .heading h2 {
    font-size: 30px;
  }
}
