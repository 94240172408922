.fullwsec {
    margin-top: 200px;
    width: 100%;
    // height: 500px;
    display: grid;
    grid-template-columns: 550px 1fr;

    .img {
        display: grid;

        img {
            width: 100%;
        }
    }

    .sndsec {
        display: grid;
        height: 100%;
        background: var(--color-pri);
        // justify-content: center;
        padding-left: 10%;
        align-items: center;

        .inner {
            width: 600px;
            p {
                margin: 0px;
                color: white;
                font-size: 18px;
            }

            .heading {
                color: white;
                font-size: 30px;
                font-weight: 600;

                span {
                    color: var(--color-sec);
                }
            }

            .mp {
                margin: 20px 0px;
            }

            .maplist {
                display: flex;

                .cat {
                    padding-right: 20px;
                }

                .activecat {
                    color: var(--color-sec);
                }
            }
        }
    }
}