.testimonialsec {
  width: 100%;

  .testisec {
    width: 1300px;
    height: auto;
    display: grid;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
    grid-template-columns: 45% 55%;
    padding: 100px 0px 50px 0px;
    grid-gap: 2rem;

    .testleftsec {
      line-height: 1;

      .h1 {
        color: var(--color-hedi);
        font-size: 30px;
        line-height: 1.1em;
        margin: 0;
      }

      p {
        margin: 0px;
        font-size: 18px;
        line-height: 1.1em;
        color: var(--color-pri);
      }
    }

    .testrightsec {
      .box {
        display: grid;
        position: relative;
        justify-content: center;
        align-items: center;
        padding: 20px 50px;
        border-radius: 15px;
        height: auto;
        background: #0a2e58;

        .quote {
          margin: 0px 10px;
          font-size: 20px;
          bottom: 0 !important;
        }

        p {
          color: white;
          font-size: 18px;
          margin: 0px;
        }

        .boximg {
          position: absolute;
          width: 150px;
          height: 150px;
          right: -95px;
          bottom: -95px;
          z-index: 9999;
          border-radius: 15px;
        }
      }
    }
  }
}

.carousel .thumbs {
  display: flex;
  justify-content: center;
}

.carousel .slider-wrapper {
  width: 90% !important;
}

.carousel-status {
  display: none;
}

.carousel .slide {
  padding-bottom: 95px;
  padding-right: 95px;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  margin-right: 0.1px;
}

.carousel .control-dots .dot {
  background: #0a2e58 !important;
}

.carousel.carousel-slider .control-arrow {
  display: none;
}
.carousel .thumbs-wrapper {
  display: none;
}

@media only screen and (max-width: 1313px) {
  .testimonialsec .testisec {
    width: 90%;
    grid-template-columns: 40% 60%;
  }
  .testimonialsec .testisec .testleftsec .h1 {
    font-size: 36px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1130px) {
  .testimonialsec .testisec {
    grid-template-columns: 1fr;
  }
  .testrightsec {
    width: 800px;
    margin: 0 auto;
  }
  .testimonialsec .testisec .testleftsec {
    width: 600px;
    margin: 0 auto;
    text-align: center;
  }
}
@media only screen and (max-width: 846px) {
  .testrightsec {
    width: 600px;
  }
}

@media only screen and (max-width: 708px) {
  .testimonialsec .testisec .testleftsec {
    width: 100%;
  }
}

@media only screen and (max-width: 622px) {
  .testimonialsec .testisec .testrightsec .box .boximg {
    display: none;
  }
  .carousel .slide {
    padding: 0px;
    padding-bottom: 50px;
  }
  .testimonialsec .testisec .testrightsec .box {
    padding: 20px;
    height: auto;
  }
  .testrightsec {
    width: 500px;
  }
  .testimonialsec .testisec {
    padding: 50px 0px;
  }
}

@media only screen and (max-width: 506px) {
  .carousel .slider-wrapper {
    width: 100% !important;
  }
  .testimonialsec .testisec .testleftsec .h1 {
    font-size: 26px;
    text-align: left;
  }
  .testrightsec {
    width: 420px;
  }
  .testimonialsec .testisec .testrightsec .box p {
    font-size: 16px !important;
  }
  .testimonialsec .testisec .testleftsec p{
    text-align: left;
  }
}

@media only screen and (max-width: 462px) {
  .testrightsec {
    width: 320px;
  }
}
