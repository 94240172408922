.Severalapp {
  width: 1068px;
  margin: 0 auto;
  height: auto;

  .severalintro {
    width: auto;
    margin: 80px auto;
    margin-bottom: 40px;

    .h1 {
      margin: 0px;
      color: var(--color-pri);
      text-align: center;
      line-height: 1.1em;
      margin-bottom: 10px;
      font-size: 30px;
    }

    p {
      margin: 0px;
      color: var(--color-pri);
      text-align: center;
      font-size: 18px;
    }
  }

  .mapcont {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(4, 220px);
    justify-content: center;
    gap: 2rem;

    .inner {
      margin: 0;
      padding: 35px 10px;
      border-radius: 6px 6px 6px 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      cursor: pointer;
      color: var(--color-pri);

      &:hover {
        background: var(--color-pri);
        color: white;

        svg {
          stroke: white;
        }
      }

      svg {
        margin-right: 10px;
        stroke: var(--color-pri);
      }
    }
  }
}
@media only screen and (max-width: 1550px) {
  .Severalapp {
    width: 80% !important;
  }
  .Severalapp .mapcont {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
}

@media only screen and (max-width: 500px) {
  .Severalapp .mapcont {
    grid-template-columns: repeat(2, 1fr);
  }
  .Severalapp .severalintro .h1 {
    text-align: left;
  }
  .Severalapp .severalintro p {
    text-align: left;
  }
  .Severalapp {
    width: 90% !important;
  }
}

@media only screen and (max-width: 500px) {
  .Severalapp .severalintro .h1 {
    font-size: 22px !important;
  }
  .Severalapp .severalintro p {
    font-size: 17px;
  }
  .Severalapp .severalintro {
    margin: 40px auto;
    margin-bottom: 20px;
  }
  .Severalapp .mapcont .inner {
    padding: 25px 10px;
  }
  .Severalapp .mapcont {
    gap: 1rem;
  }
}
@media only screen and (max-width: 350px) {
  .Severalapp .mapcont {
    grid-template-columns: 1fr;
  }
}
