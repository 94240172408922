.faq {
  width: 100%;
  height: auto;
  margin-top: 30px;

  .faqinner {
    width: 1068px;
    height: 100%;
    margin: 0 auto;
    // background: pink;
    display: flex;
    justify-content: center;
    align-items: center;

    .accordion {
      width: 100%;
      margin: 0px;

      .heading {
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        color: var(--color-pri);
        margin-bottom: 30px;
        margin-top: 20px;
      }

      .innermao {
        height: auto;
        margin-bottom: 10px;

        .wraper {
          display: grid;
          grid-template-columns: 1fr 25px;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          // border-radius: var(--border-radius);
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          padding: 15px 25px;
          cursor: pointer;
          background: var(--color-pri);
          color: white;

          .acctitle {
            margin: 0px;
            padding: 0px;
            p {
              margin: 0px;
              font-size: 18px;
            }
            h4 {
              font-size: 16px;
              margin: 0px;
              line-height: 1.7em;
            }
          }

          .spanr {
            font-size: 20px;
            font-weight: bolder;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
        }

        .accpara {
          background: white;
          margin: 0px;
          padding: 5px 40px;
          text-align: justify;
          line-height: 1.7em;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;

          p {
            font-size: 17px;
          }
        }

        .hide {
          display: none;
        }

        .active {
          background: #e7f1ff;
        }
      }
    }
  }
}
.faq .faqinner .accordion .innermao .wraper {
  box-sizing: border-box;
}

@media screen and (max-width: 1500px) {
  .faq .faqinner {
    width: 80%;
  }
}

@media screen and (max-width: 550px) {
  .faq .faqinner .accordion .innermao .accpara {
    padding: 5px 20px;
  }
  .faq .faqinner {
    width: 90%;
  }
  .faq .faqinner .accordion .innermao .wraper {
    padding: 20px;
  }
  .faq .faqinner .accordion .heading {
    margin-bottom: 20px;
    margin-top: 0px;
  }
}

// @media screen and (max-width: 946px) {
//   .faq .faqinner {
//     width: 100%;
//   }
//   .faq .faqinner .accordion {
//     width: 90%;
//   }
// }
// @media screen and (max-width: 750px) {
//   .faq .faqinner .accordion .innermao .accpara {
//     padding: 0px 10px;
//   }
//   .faq .faqinner .accordion .heading {
//     margin: 0px;
//     margin-bottom: 20px;
//   }
// }
