.smo {
  width: 100%;
  height: auto;

  .CustomSubbanner {
    img {
      width: 600px !important;
      bottom: -130px !important;
    }
  }
  .emergingtec {
    margin: 50px 0px;
  }
}
@media only screen and (max-width: 800px) {
  .smo .CustomSubbanner img {
    width: 500px !important;
  }
  .smo .CustomSubbanner {
    height: 470px;
    margin-bottom: 200px !important;
  }
}
@media only screen and (max-width: 500px) {
  .smo .CustomSubbanner img {
    width: 350px !important;
    bottom: -100px !important;
  }
  .smo .CustomSubbanner {
    height: 390px !important;
    margin-bottom: 150px !important;
  }
}
