.portfoliosec {
  width: 100%;

  .inner {
    width: 100%;
    background: var(--color-pri);

    .filter {
      width: 100%;
      // background: red;
      display: flex;
      justify-content: center;
      gap: 15px;
      padding-bottom: 30px;

      button {
        width: 120px;
        height: 40px;
        border: none;
        outline: none;
        border-radius: var(--border-radius);
        font-size: 15px;
        font-weight: bold;
      }

      .active {
        background: var(--color-sec);
        color: var(--color-pri);
      }

      .inactive {
        color: white;
        background: none;
        border: 1px solid white;
      }
    }
  }

  .portfoliosecinner {
    width: 100%;
    height: auto;

    .wraper {
      width: 100%;
      height: 500px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      .innerreverse {
        width: 1300px;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        background: none;
        margin: 0 auto;
        align-items: center;
        grid-auto-flow: dense;
        direction: rtl;
        gap: 5rem;
        color: white;

        .left {
          h1 {
            line-height: 1em;
          }

          p {
            margin: 0px;
            margin: 15px 0px;
            font-size: 18px;
          }

          button {
            width: 120px;
            height: 40px;
            background: none;
            outline: none;
            border-radius: var(--border-radius);
            font-size: 15px;
            color: white;
            border: 1px solid white;

            &:hover {
              background: var(--color-sec);
              color: var(--color-pri);
              outline: none;
              border: none;
              font-weight: 600;
            }
          }
        }

        .right {
          display: grid;
          width: 100%;

          img {
            display: grid;
            width: 100%;
          }
        }
      }

      .inner {
        width: 1300px;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        background: none;
        margin: 0 auto;
        align-items: center;
        gap: 5rem;
        color: white;

        .left {
          h1 {
            line-height: 1em;
          }

          p {
            margin: 0px;
            margin: 15px 0px;
            font-size: 18px;
          }

          button {
            width: 120px;
            height: 40px;
            background: none;
            outline: none;
            border-radius: var(--border-radius);
            font-size: 15px;
            color: white;
            border: 1px solid white;

            &:hover {
              background: var(--color-sec);
              color: var(--color-pri);
              outline: none;
              border: none;
              font-weight: 600;
            }
          }
        }

        .right {
          display: grid;
          width: 100%;

          img {
            display: grid;
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1403px) {
  .portfoliosec .portfoliosecinner .wraper .inner {
    width: 90%;
  }

  .portfoliosec .portfoliosecinner .wraper .innerreverse {
    width: 90%;
  }
}

@media only screen and (max-width: 1000px) {
  .portfoliosec .portfoliosecinner .wraper {
    padding-top: 50px;
    height: auto;
    padding-bottom: 50px;
  }
  .portfoliosec .portfoliosecinner .wraper .inner {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .portfoliosec .portfoliosecinner .wraper .innerreverse {
    grid-template-columns: 1fr;
    text-align: left;
    gap: 2rem;
  }
}

@media only screen and (max-width: 464px) {
  .portfoliosec .portfoliosecinner .wraper .innerreverse .left p {
    font-size: 17px;
  }
  .portfoliosec .portfoliosecinner .wraper .inner .left p {
    font-size: 17px;
  }
}
