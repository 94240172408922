.seo {
  width: 100%;
  height: auto;
  .CustomSubbanner img {
    width: 500px !important;
    bottom: -190px !important;
  }
  .emergingtec {
    margin: 50px 0px;
  }
}

@media only screen and (max-width: 781px) {
  .seo .CustomSubbanner img {
    width: 400px !important;
    bottom: -150px !important;
  }
  .seo .CustomSubbanner {
    margin-bottom: 200px;
  }
  .seo .CustomSubbanner .heading {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 437px) {
  .seo .CustomSubbanner {
    height: 400px;
    margin-bottom: 180px;
  }
  .seo .CustomSubbanner img {
    width: 320px !important;
    bottom: -120px !important;
  }
}
