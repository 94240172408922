.Mobileappdevelopmentservice {
  width: 100%;
  height: auto;
  .CustomSubbanner {
    height: 550px;
    img {
      width: 450px !important;
      bottom: -130px !important;
    }
  }
  .emergingtec {
    margin: 50px 0px;
  }
}

@media only screen and (max-width: 1101px) {
  .Mobileappdevelopmentservice {
    .CustomSubbanner .heading {
      padding-top: 65px;
    }
  }
}
@media only screen and (max-width: 1101px) {
  .Mobileappdevelopmentservice .CustomSubbanner img {
    width: 400px !important;
  }
}

@media only screen and (max-width: 487px) {
  .CustomSubbanner .heading h1 {
    font-size: 26px;
  }
  .Mobileappdevelopmentservice .CustomSubbanner {
    height: 500px;
  }
  .Mobileappdevelopmentservice .CustomSubbanner img {
    width: 320px !important;
  }
  .Mobileappdevelopmentservice .CustomSubbanner .heading {
    padding-top: 50px;
  }
}
