.brandslider {
    width: 100%;

    .inner {
        margin: 0 auto;
        width: 60%;
        height: 200px;

        p {
            text-align: center;
            width: 70%;
            margin: 0 auto;
            font-size: 18px;
        }


        .slider {
            margin-top: 50px;

            // margin: 0px 50px;
            img {
                width: 80%;
            }
        }
    }
}