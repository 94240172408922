.erangle {
  width: 100%;
  height: 450px;
  position: relative;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  .data {
    width: 1300px;
    margin: 0 auto;
    color: white;
    display: grid;
    height: 100%;
    align-items: center;

    h3 {
      margin: 0px;
      line-height: 1em;
      font-size: 36px;

      span {
        color: var(--color-sec);
      }
    }

    p {
      margin: 0px;
      width: 500px;
      margin: 10px 0px;
      font-size: 18px;
    }

    button {
      width: 120px;
      background: none;
      color: white;
      border: 1px solid white;
      border-radius: var(--border-radius);
      padding: 8px 12px;
    }
  }

  .img {
    position: absolute;
    width: 100%;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
}
@media only screen and (max-width: 1550px) {
  .erangle .data {
    width: 80%;
  }
  .erangle {
    height: 330px;
  }
}

@media only screen and (max-width: 823px) {
  .erangle {
    background-position: top left;
  }
}
@media only screen and (max-width: 620px) {
  .erangle {
    background-position: center;
  }
  .erangle .data p {
    width: 100%;
  }
  .erangle .data {
    width: 90%;
  }
  .erangle {
    height: auto;
    padding: 50px 0px;
  }
  .erangle .data p {
    font-size: 17px;
  }
}
