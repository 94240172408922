.fi {
  fill: var(--color-sec);
}

.wrap {
  position: relative;
  margin-top: 550px;
  .Contactformcont {
    position: absolute;
    z-index: 66;
    left: 50%;
    transform: translateX(-50%);
    top: -490px;
  }

  .Footerv1 {
    width: 100%;
    height: auto;
    background-color: #091b3f;
    color: white;
    // display: grid;
    align-items: center;
    position: relative;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    .st1 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #fff;
    }

    #Layer_1 {
      position: absolute;
      top: 0;
    }

    .Footerinner {
      width: 1300px;
      margin: 0 auto;
      margin-top: 190px;

      .v1remote {
        display: grid;
        grid-template-columns: 1fr 100px;
        margin: 0px 100px;
        padding: 100px 90px;

        // margin-bottom: 113px;
        // padding: 120px 200px;

        .v1fd {
          width: 700px;
          // background: pink;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .v1h1 {
            line-height: 1;
            margin-bottom: 10px;
            letter-spacing: -0.5px;
            display: flex;
            align-items: flex-end;
            font-size: 40px;
            font-size: 800;
            position: relative;
            img {
              width: 80px;
              margin-left: 17px;
              transform: rotate(341deg);
              position: absolute;
              left: 300px;
              bottom: -15px;
            }
            .ready {
              margin-left: 10px;
              font-size: 50px;
              font-weight: 600;
            }
          }

          .v1h3 {
            font-size: 30px;
            line-height: 1.3;
            letter-spacing: -0.15px;
            color: #ffb248;
            font-weight: bolder;
          }
        }

        .v12d {
          display: grid;

          a {
            color: white !important;
            text-decoration: none;
            font-size: 20px;
            margin-bottom: 20px;
            transition: all 0.5s ease;
          }

          a:hover {
            // color: #4c5875 !important;
            color: #ffb248 !important;
          }
        }
      }

      .cont1 {
        width: 100%;
        display: flex;
        justify-self: center;

        .v1links {
          width: 100%;
          margin: 0;
          padding: 15px 100px;
          display: grid;
          grid-template-columns: 50% 50%;
          border-top: 1px solid rgba(216, 216, 216, 0.2);

          .v1slinks {
            display: flex;
            padding: 0px;

            .v1icon {
              font-size: 18px;

              display: flex;
              // justify-self: center;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 40px;
              border-radius: 20px;
              border: 1px solid hsla(0, 0%, 100%, 0.2) !important;
              margin-right: 25px;

              a {
                border: none;
                display: flex;
                color: white;
              }
            }
          }

          .v1cc {
            font-size: 15px;
            display: flex;
            justify-content: right;

            li {
              line-height: 1.5;
              font-weight: 500;
              margin: 0px;
              margin-left: 20px;
              display: flex;
              align-items: center;

              a {
                margin: 0px;
                padding: 0px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1297px) {
  .wrap .Footerv1 .Footerinner {
    width: 90%;
  }

  .wrap .Footerv1 .Footerinner .v1remote .v1fd {
    width: auto;
  }
}

@media only screen and (max-width: 947px) {
  .wrap {
    margin-top: 540px;
  }
  .wrap .Footerv1 .Footerinner {
    margin-top: 560px;
  }
}
@media only screen and (max-width: 862px) {
  .wrap .Footerv1 .Footerinner .v1remote {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .wrap .Footerv1 .Footerinner .v1remote .v12d {
    grid-template-columns: repeat(2, 1fr);
  }
  .wrap .Footerv1 .Footerinner .cont1 .v1links {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
  }
  .wrap .Footerv1 .Footerinner .cont1 .v1links .v1cc {
    padding: 0px;
  }
  .wrap .Footerv1 .Footerinner .cont1 .v1links .v1cc li {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 655px) {
  .wrap .Footerv1 .Footerinner .v1remote {
    padding: 100px 20px;
  }
  .wrap .Footerv1 .Footerinner {
    margin-top: 500px;
  }
  .wrap .Footerv1 .Footerinner .v1remote .v1fd .v1h1 {
    font-size: 40px;
  }
  .wrap .Footerv1 .Footerinner .v1remote .v1fd .v1h3 {
    font-size: 24px;
  }
  .wrap .Footerv1 .Footerinner .cont1 .v1links .v1slinks .v1icon {
    margin-right: 10px;
  }
  .wrap .Footerv1 .Footerinner .cont1 .v1links .v1cc li {
    margin: 0px;
  }
  .wrap .Footerv1 .Footerinner .cont1 .v1links .v1cc {
    padding: 0px;
  }
  .wrap .Footerv1 .Footerinner .cont1 .v1links {
    grid-template-columns: 1fr auto;
    justify-content: flex-start;
  }
  .wrap .Footerv1 .Footerinner .cont1 .v1links .v1slinks {
    padding: 0px;
  }
}

@media only screen and (max-width: 499px) {
  .wrap .Footerv1 .Footerinner {
    margin-top: 350px;
  }
  .wrap .Footerv1 .Footerinner .v1remote {
    padding: 100px 20px 30px;
  }
  .wrap .Footerv1 .Footerinner .cont1 .v1links {
    grid-template-columns: 1fr;
    padding: 15px 12px;
  }
  .wrap .Footerv1 .Footerinner .v1remote .v1fd .v1h3 {
    font-size: 20px;
  }
  .wrap .Footerv1 .Footerinner .v1remote .v12d a {
    margin-bottom: 10px;
  }
  .wrap .Footerv1 .Footerinner .v1remote .v12d {
    grid-template-columns: repeat(2, 1fr);
  }
  .Contactformcont .contactforminner .contactr .inputfield {
    box-sizing: border-box;
    width: 100%;
  }
  .wrap .Footerv1 .Footerinner .v1remote .v1fd .v1h1 {
    display: grid;
    grid-template-columns: auto 1fr;
  }
  .wrap .Footerv1 .Footerinner .v1remote .v1fd .v1h1 .aare {
    grid-column: span 2;
  }
  .wrap .Footerv1 .Footerinner .v1remote .v1fd .v1h1 .ready {
    margin-left: 0px;
  }
  .wrap .Footerv1 .Footerinner .v1remote .v1fd .v1h1 img {
    left: 170px;
  }
}
