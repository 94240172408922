.portfoliobanner {
  width: 100%;
  // height: 200px;
  padding: 50px 0px;
  background: var(--color-pri);
  display: grid;
  align-items: center;

  .inner {
    width: 750px;
    margin: 0 auto;
    display: grid;
    justify-items: center;

    h1 {
      text-align: center;
      color: white;
      line-height: 1.1em;

      span {
        color: var(--color-sec);
      }
    }

    p {
      text-align: center;
      color: white;
      margin: 0px;
      font-size: 18px;
      margin: 5px 0px;
      margin-bottom: 20px;
    }
    a {
      width: 120px;
      height: 40px;
      background: var(--color-sec);
      border: none;
      outline: none;
      border-radius: var(--border-radius);
      color: var(--color-pri);
      font-size: 15px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
    margin: 0 auto;
  }
}
@media screen and (max-width: 999px) {
  .portfoliobanner .inner {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .portfoliobanner .inner {
    width: 90%;
  }
  .portfoliobanner .inner h1 {
    font-size: 26px !important;
  }
  .portfoliobanner .inner p {
    font-size: 17px;
  }
  .portfoliosec .portfoliosecinner .wraper .innerreverse .left p {
    font-size: 17px;
  }
}
