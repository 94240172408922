.blogdetailpage {
  width: 100%;
  height: auto;
  .innerblogdetail {
    width: 1000px;
    margin: 0 auto;
    margin-top: 80px;
    img {
      box-sizing: border-box;
      width: 100%;
      margin-top: 20px;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-top: 15px;
      color: var(--color-pri);
      line-height: 1.1em;
    }
    p {
      font-size: 18px;
      line-height: 1.5em;
      margin: 0px;
      margin-top: 10px;
    }
    ul {
      padding: 0px 20px;
    }
  }
}
@media only screen and (max-width: 1300px) {
  .blogdetailpage .innerblogdetail {
    width: 90%;
  }
}
@media only screen and (max-width: 800px) {
  .blogdetailpage .Blogbanner {
    background-position: left;
    height: 300px;
  }
  .blogdetailpage .innerblogdetail p {
    font-size: 17px;
  }
  // ------
  .blogdetailpage {
    .innerblogdetail {
      margin-top: 40px;
      img {
        box-sizing: border-box;
        width: 100%;
      }
      h1,
      h2,
      h3,
      h4,
      h5 {
        margin-top: 15px;
        color: var(--color-pri);
        line-height: 1.1em;
      }
      p {
        font-size: 17px;
        line-height: 1.5em;
        margin: 0px;
        margin-top: 10px;
      }
      ul {
        padding: 0px 20px;
      }
    }
  }
}
