.sem {
  width: 100%;
  height: auto;
  .CustomSubbanner {
    img {
      width: 600px !important;
      bottom: -130px !important;
    }
  }
  .emergingtec {
    margin: 50px 0px;
  }
}
@media only screen and (max-width: 800px) {
  .sem .CustomSubbanner img {
    width: 450px !important;
    bottom: -100px !important;
  }
  .sem .CustomSubbanner {
    margin-bottom: 200px !important;
  }
  .sem .CustomSubbanner .heading {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 500px) {
  .sem .CustomSubbanner .heading h1 {
    font-size: 26px !important;
  }
  .sem .CustomSubbanner {
    height: 400px !important;
  }
  .sem .CustomSubbanner img {
    width: 350px !important;
    bottom: -80px !important;
  }
  .sem .CustomSubbanner {
    margin-bottom: 150px !important;
  }
}
