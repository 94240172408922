.Shopifydevelopmentservice {
  width: 100%;
  height: auto;

  .emergingtec {
    margin: 50px 0px;
  }
}

@media only screen and (max-width: 934px) {
  .Shopifydevelopmentservice .CustomSubbanner img {
    width: 550px !important;
    bottom: -200px !important;
  }

  .Shopifydevelopmentservice .CustomSubbanner .heading {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 608px) {
  .Shopifydevelopmentservice .CustomSubbanner img {
    width: 450px !important;
    bottom: -140px !important;
  }
  .Shopifydevelopmentservice .CustomSubbanner {
    margin-bottom: 180px !important;
  }
}

@media only screen and (max-width: 500px) {
  .Shopifydevelopmentservice .CustomSubbanner .heading h1 {
    font-size: 26px !important;
  }
  .Shopifydevelopmentservice .CustomSubbanner {
    height: 400px;
  }
  .Shopifydevelopmentservice .CustomSubbanner img {
    width: 380px !important;
    bottom: -140px !important;
  }
}
