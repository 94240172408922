:root {
  width: 100%;
  --color-white: #fff;
  --color-pri: #091b3f;
  --color-sec: #fab049;
  --color-hedi: #234888;
  --color-back: #f0f6ff;
  --text-line-height: 1.4em;
  --text-font-size: 1em;
  --border-radius: 5px;
}

body,
html {
  overflow-x: hidden;
  width: 100%;
  font-family: "Heebo", sans-serif !important;
}
div {
  box-sizing: border-box;
}

.App {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  font-family: "Heebo", sans-serif !important;
}

p {
  font-family: "Heebo", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "GilroyBold"; */
  font-family: "Heebo", sans-serif !important;
  margin: 0px;
}

h1 {
  font-size: 36px !important;
}
h2 {
  font-size: 33px !important;
}
h3 {
  font-size: 30px !important;
}
h4 {
  font-size: 27px !important;
}

button,
input,
optgroup,
select,
textarea,
a {
  font-family: "Heebo", sans-serif !important;
}

p {
  font-size: 16px;
}
@media only screen and (max-width: 500px) {
  h1 {
    font-size: 30px !important;
  }
  h2 {
    font-size: 27px !important;
  }
  h3 {
    font-size: 24px !important;
  }
  h4 {
    font-size: 21px !important;
  }
}
