.wrapins {
  width: 100%;
  margin: 100px 0px;
  background-color: var(--color-pri);
  .clients {
    padding: 50px 0px;
    width: 1300px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    .pri {
      display: grid;
      justify-content: center;
      text-align: center;
      .fle {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white !important;
        p {
          margin-left: 2px;
          font-size: 24px !important;
          // color: var(--color-sec);
        }
        .cup {
          // color: var(--color-sec);
          font-size: 46px;
          margin: 0px;
          font-weight: bolder;
        }
      }
      h2 {
        color: white;
        font-size: 46px !important;
        margin: 0px;
        font-weight: bolder;
      }
      .cup {
        color: white;
        font-size: 46px;
        margin: 0px;
        font-weight: bolder;
      }
      p {
        margin: 0px;
        color: white;
      }
    }

    .sec {
      display: grid;
      justify-content: center;
      text-align: center;
      .fle {
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          margin-left: 2px;
          font-size: 24px !important;
          color: var(--color-sec);
        }
        .cup {
          color: var(--color-sec);
          font-size: 46px;
          margin: 0px;
          font-weight: bolder;
        }
      }
      .h2 {
        color: var(--color-sec);
        font-size: 46px;
        margin: 0px;
        font-weight: bolder;
      }
      p {
        margin: 0px;
        color: white;
      }
    }
  }
}
@media only screen and (max-width: 1550px) {
  .wrapins .clients {
    width: 90%;
  }
}
@media only screen and (max-width: 816px) {
  .wrapins .clients {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}
@media only screen and (max-width: 547px) {
  .wrapins {
    margin: 50px 0px;
  }
  .wrapins .clients {
    grid-template-columns: repeat(2, 1fr);
  }
  .wrapins .clients .sec {
    justify-items: center;
  }
  .wrapins .clients .pri {
    justify-items: center;
  }
  .wrapins .clients .sec h2 {
    font-size: 36px;
  }
  .wrapins .clients .pri h2 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 422px) {
  .wrapins .clients .sec p {
    font-size: 16px !important;
    text-align: center;
  }
  .wrapins .clients .pri p {
    font-size: 16px !important;
    text-align: center;
  }
  .wrapins .clients {
    gap: 1rem;
  }
}
