.Reactdevelopmentservice {
  width: 100%;
  height: auto;
  .CustomSubbanner {
    img {
      width: 700px !important;
      bottom: -180px !important;
    }
  }
  .emergingtec {
    margin: 50px 0px;
  }
}

@media only screen and (max-width: 960px) {
  .Reactdevelopmentservice {
    .CustomSubbanner {
      img {
        width: 600px !important;
        bottom: -160px !important;
      }
    }
  }
}

@media only screen and (max-width: 770px) {
  .Reactdevelopmentservice {
    .CustomSubbanner {
      img {
        width: 550px !important;
        bottom: -150px !important;
      }
    }
  }
}
@media only screen and (max-width: 546px) {
  .Reactdevelopmentservice .CustomSubbanner {
    height: 400px;
    margin-bottom: 180px !important;
    img {
      width: 420px !important;
      bottom: -100px !important;
    }
  }
}

@media only screen and (max-width: 416px) {
  .Reactdevelopmentservice {
    .CustomSubbanner {
      height: 400px;
      margin-bottom: 140px !important;
      img {
        width: 350px !important;
        bottom: -100px !important;
      }
    }
  }
}
