.Dynamiccontentwh {
  width: 100%;
  margin: 10px 0px;
  height: auto;

  .inner {
    width: 1068px;
    margin: 0 auto;
    color: var(--color-pri);
    padding: 90px 50px;

    p {
      margin: 0px;
      font-size: 18px;
    }
    h1 {
      font-size: 45px;
      font-weight: 600;
      line-height: 1.1em;
      margin: 0 0 10px;
    }
    .hedi {
      font-size: 45px;
      font-weight: 600;
      line-height: 1.1em;
      margin: 0 0 10px;
    }

    .subhedi {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 10px;
      line-height: 1.1em;
    }

    .sec {
      p {
        font-size: 20px;
        line-height: 1.5em;
        margin-bottom: 10px;
        &:nth-last-child(1) {
          margin-bottom: 0px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1550px) {
  .Dynamiccontentwh .inner {
    width: 750px;
  }
  .Dynamiccontentwh .inner {
    padding: 90px 0px;
  }
}

@media only screen and (max-width: 1000px) {
  .Dynamiccontentwh .inner {
    width: 80%;
  }
}

@media only screen and (max-width: 550px) {
  .Dynamiccontentwh .inner {
    padding: 60px 0px;
    width: 90%;
  }
  // .Dynamiccontentwh .inner .sec {
  //   height: 300px;
  //   overflow-y: scroll;
  //   &::-webkit-scrollbar {
  //     width: 5px;
  //   }

  //   &::-webkit-scrollbar-track {
  //     background-color: #bfbfbf;
  //   }

  //   &::-webkit-scrollbar-thumb {
  //     background-color: var(--color-pri);
  //     border-radius: 4px;
  //   }
  // }
  .Dynamiccontentwh .inner .sec p {
    font-size: 17px;
  }
}
