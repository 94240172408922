.webdevelopment {
  width: 100%;
  height: auto;

  .emergingtec {
    margin: 50px 0px;
  }
}

@media only screen and (max-width: 967px) {
  .webdevelopment .CustomSubbanner .image {
    width: 700px !important;
    bottom: -140px;
  }
  .CustomSubbanner .heading p {
    width: 100%;
  }
}
@media only screen and (max-width: 761px) {
  .webdevelopment .CustomSubbanner .image {
    width: 500px !important;
    bottom: -100px !important;
  }
  .CustomSubbanner {
    height: 430px;
  }
}

@media only screen and (max-width: 499px) {
  .webdevelopment .CustomSubbanner .image {
    width: 380px !important;
    bottom: -76px !important;
  }
  .webdevelopment .CustomSubbanner {
    margin-bottom: 100px !important;
  }
}
