.appcta {
    width: 100%;
    padding: 50px 0px;

    .insight {
        width: 800px;
        margin: 0 auto;
        height: 100%;
        display: grid;
        grid-template-columns: 200px 200px 200px;
        justify-content: space-evenly;

        div {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-self: center;
            cursor: pointer;

            p {
                margin: 0px;

            }

            .lh {
                line-height: 1em;
            }

            .val {
                font-size: 40px;
                font-weight: 600;
                margin: 0px;
                color: var(--color-sec);

                &:hover {
                    color: var(--color-pri);
                }
            }
        }
    }
}