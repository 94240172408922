.Contactformcont {
  width: 1000px !important;
  letter-spacing: 1.2px;
  box-shadow: 0 -1px 20px 0 #766c6c33;
  width: 80%;
  margin: 0 auto;
  border-radius: 30px;
  .contactforminner {
    margin: 0 auto;
    border-radius: 47px;
    position: relative;
    padding: 80px 100px;
    background: #fff;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .contactr {
      display: grid;

      form {
        display: grid;
      }

      .inputfield {
        height: 55px;
        padding: 0 72px 0 24px;
        background: rgba(244, 244, 244, 0.801191);
        border-radius: 12px;
        border: 2px solid rgba(244, 244, 244, 0.801191);
        font-size: 18px;
        color: #111029;
        transition: all 0.25s;
      }

      .inputfield:focus {
        border: 2px solid #4c40f7;
      }

      .textarea {
        padding: 10px 72px 0 24px;
        // width: 100%;
        background: rgba(244, 244, 244, 0.801191);
        border-radius: 12px;
        border: 2px solid rgba(244, 244, 244, 0.801191);
        font-size: 18px;
        color: #111029;
        transition: all 0.25s;
        margin-bottom: 40px;
        resize: none;
      }

      .textarea:focus {
        border: 2px solid #4c40f7;
      }

      .inpbtn {
        height: 55px;
        border-radius: var(--border-radius);
        font-size: 18px;
        font-weight: 500;
        transition: all 0.25s;
        background: #091b3f;
        color: #fff;
        outline: none;
        border: none;
        background: #091b3f;

        &:hover {
          background: #ffb248;
        }
      }
    }

    .contactinner {
      .contacth3 {
        margin: 0px;
        margin-bottom: 15px;
        padding-left: 68px px;
        font-size: 24px;
        line-height: 1.4;
        font-weight: 700;
        color: #ffb248;
      }

      .contacth1 {
        color: var(--color-pri);
        font-size: 62px;
        line-height: 1.16667;
        letter-spacing: -0.5px;
        font-weight: 700;
        margin: 0px;
      }

      .contactpara {
        font-size: 18px;
        width: 80%;
        margin-bottom: 50px;
      }

      .contacticonsec {
        display: flex;
        margin-bottom: 40px;
        &:nth-last-child(1) {
          margin-bottom: 0px;
        }

        a {
          text-decoration: none;
          color: black;
        }

        .contacticons {
          margin-right: 40px;
          color: #ffb248;
          font-size: 22px;
        }

        p {
          font-size: 18px;
          margin: 0px;
          padding-top: 3px;
        }
      }
    }
  }
}

.wrap .Footerv1 .Footerinner .v1remote {
  margin: 0px;
}

@media only screen and (max-width: 1097px) {
  .Contactformcont .contactforminner {
    padding: 80px;
  }
  .Contactformcont {
    width: 900px !important;
  }
}

@media only screen and (max-width: 947px) {
  .Contactformcont .contactforminner {
    grid-template-columns: 1fr;
  }
  .Contactformcont .contactforminner .contactinner .contacticonsec {
    margin-bottom: 20px;
    &:nth-last-child(1) {
      margin-bottom: 20px;
    }
  }
  .Contactformcont .contactforminner .contactinner .contactpara {
    margin: 0px;
    margin-bottom: 20px;
  }
  .Contactformcont {
    width: 90% !important;
  }
  .Contactformcont
    .contactforminner
    .contactinner
    .contacticonsec
    .contacticons {
    margin-right: 20px;
    font-size: 26px;
  }

  .Contactformcont .contactforminner .contactinner .contacth3 {
    margin: 0px;
  }
  .Contactformcont .contactforminner .contactr .inputfield {
    margin-bottom: 20px;
  }
  .Contactformcont .contactforminner .contactr .textarea {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 655px) {
  .Contactformcont .contactforminner .contactinner .contacth1 {
    font-size: 40px;
  }
  .Contactformcont .contactforminner {
    padding: 50px;
  }
  .Contactformcont .contactforminner .contactinner .contactpara {
    width: 100%;
    margin-bottom: 10px !important;
  }
  .Contactformcont .contactforminner .contactinner {
    border-radius: 15px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 499px) {
  .Contactformcont .contactforminner {
    padding: 30px;
  }

  .Contactformcont .contactforminner .contactinner .contacticonsec {
    margin-bottom: 10px !important;
    &:nth-last-child(1) {
      margin-bottom: 0px;
    }
  }
  .Contactformcont .contactforminner .contactinner .contacth1 {
    font-size: 30px;
  }
}
