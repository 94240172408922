.Blogbanner {
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .blogbannerinner {
    color: white;
  }
}
