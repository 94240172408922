.Wearecreative {
  width: 1300px;
  height: auto;
  margin: 0 auto;
  .digiex {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0px 0px 100px 0px;
    margin: 0 auto;
    justify-content: space-around;
    align-items: center;
    .bor {
      border-right: 2px solid var(--color-hedi);
      .hedi {
        width: 500px;
        margin: 0 auto;
        text-align: center;
        h4 {
          color: var(--color-hedi);
          font-size: 26px;
        }

        p {
          margin: 0px;
          margin: 0 auto;

          span {
            // color: var(--color-hedi);
            // font-weight: bold;
          }
        }
      }
    }
    .texi {
      width: 500px;
      margin: 0 auto;
      text-align: center;

      h4 {
        color: var(--color-hedi);
        font-size: 26px;
      }

      p {
        margin: 0px;
        margin: 0 auto;

        span {
          // color: var(--color-hedi);
          // font-weight: bold;
        }
      }
    }
  }
}
@media only screen and (max-width: 1550px) {
  .Wearecreative {
    width: 90%;
  }
}
@media only screen and (max-width: 1166px) {
  .Wearecreative .digiex {
    grid-template-columns: 1fr;
  }
  .Wearecreative .digiex .bor {
    border-right: none;
    border-bottom: 2px solid var(--color-hedi);
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
  .Wearecreative .digiex {
    width: 500px;
  }
}
@media only screen and (max-width: 546px) {
  .Wearecreative .digiex {
    padding-bottom: 50px;
    width: 100%;
  }
  .Wearecreative .digiex .bor .hedi p span {
    text-align: center;
  }
  .Wearecreative .digiex {
    width: 100%;
  }
  .Wearecreative .digiex .bor .hedi p {
    text-align: center;
  }
  .Wearecreative .digiex .texi p {
    text-align: center;
  }
  .Wearecreative .digiex .bor .hedi {
    width: 100%;
  }
  .Wearecreative .digiex .texi {
    width: 100%;
  }
  .Wearecreative .digiex .bor .hedi h4 {
    font-size: 26px !important;
  }
  .Wearecreative .digiex .texi h4 {
    font-size: 26px !important;
  }
}
