.builtin {
    width: 800px;
    height: auto;
    margin: 0 auto;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;

    h1 {
        margin: 0px;
        color: var(--color-pri);
    }

    p {
        margin: 0px;
        color: var(--color-pri);
        font-size: 18px;
    }

    .flex {
        display: flex;
        margin-top: 30px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        .innerdiv {
            width: 200px;
            // padding: 20px 20px;
            // border: 1px solid var(--color-pri);
        }
    }

}