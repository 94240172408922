.description {
  width: 100%;
  height: auto;
  padding: 90px 0px;
  height: auto;
  background: var(--color-pri);

  .data {
    height: 100%;
    display: flex;
    flex-direction: column;
    color: var(--color-sec);
    width: 1100px;
    margin: 0 auto;

    .inner {
      .conboinner {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        h1 {
          font-size: 36px;
          line-height: 1.1em;
          margin: 0 auto;
          text-align: center;
          width: 600px;
        }

        h2 {
          font-size: 36px;
          line-height: 1.1em;
          margin: 0 auto;
          text-align: center;
          width: 600px;
        }

        h3 {
          font-weight: 100;
          margin: 0px auto;
          font-size: 25px;
          width: 600px;
          line-height: 1.3em;
          text-align: center;
        }
        p {
          color: white;
          margin: 0px auto;
          font-size: 18px;
          margin-top: 20px;
          width: 600px;
          text-align: center;
        }
      }

      ul.twocolsec {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        gap: 1.5rem;
        justify-content: center;
        width: 1000px;
        margin: 0 auto;
        margin-top: 20px;
        padding: 0px;
        li {
          width: 30%;
          font-size: 18px;
          color: white;
          list-style-type: none;
          display: flex;
          align-items: center;
          justify-content: center;
          .icon {
            margin-right: 5px;
            font-size: 20px;
            color: var(--color-sec);
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1550px) {
  .description .data {
    width: 80%;
  }
  .description .data .inner .conboinner h1 {
    width: 100%;
  }
  .description .data .inner .conboinner h2 {
    width: 100%;
  }
  .description .data .inner .conboinner h3 {
    width: 100%;
  }
  .description .data .inner .conboinner p {
    width: 100%;
  }
  .description .data .inner ul.twocolsec {
    width: 100%;
  }
}
@media only screen and (max-width: 1050px) {
  .description .data .inner ul.twocolsec {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .description .data .inner ul.twocolsec li {
    width: 100%;
  }
}

@media only screen and (max-width: 550px) {
  .description .data .inner ul.twocolsec {
    grid-template-columns: 1fr;
  }
  .description .data .inner .conboinner h1 {
    text-align: left;
  }
  .description .data .inner .conboinner h2 {
    text-align: left;
  }
  .description .data .inner .conboinner h3 {
    text-align: left;
  }
  .description .data .inner .conboinner p {
    text-align: left;
  }
  .description .data {
    width: 90%;
  }
  .description {
    padding: 80px 0px;
  }
  .description .data .inner ul.twocolsec {
    justify-content: flex-start;
    padding: 0px;
  }
  .description .data .inner ul.twocolsec li {
    width: 100%;
    font-size: 17px;
  }
  .description .data .inner ul.twocolsec li {
    justify-content: flex-start;
  }
  .description .data .inner ul.twocolsec{
    gap: 1rem;
  }
}
