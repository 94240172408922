.Digitalmarketinginner {
    width: 100%;

    .inner {
        width: 80%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;

        .left {
            h1 {
                color: var(--color-pri);
                line-height: 1.1em;
                font-size: 46px;

                span {
                    color: var(--color-sec);
                }
            }
        }

        .right {
            .wrap {
                width: 80%;
                margin: 0px;
                margin: 0 auto;
                margin-bottom: 20px;

                img {
                    width: 100px;
                }

                h1 {
                    font-size: 26px;
                    margin-top: -10px;
                    margin-bottom: 5px;
                }

                p {
                    margin: 0px;
                    font-size: 18px;
                }
            }
        }

    }
}