.button {
    width: 120px;
    height: 40px;
    background: var(--color-sec);
    border: none;
    outline: none;
    border-radius: var(--border-radius);
    color: var(--color-pri);
    font-size: 15px;
    font-weight: bold;
}