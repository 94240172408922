.smm {
  width: 100%;
  height: auto;

  .CustomSubbanner {
    img {
      width: 550px !important;
      bottom: -160px !important;
      margin-bottom: 10px !important;
    }
  }
  .emergingtec {
    margin: 50px 0px;
  }
}

@media only screen and (max-width: 847px) {
  .smm .CustomSubbanner img {
    width: 450px !important;
  }
  .smm .CustomSubbanner .heading {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 485px) {
  .smm .CustomSubbanner img {
    width: 400px !important;
    bottom: -130px !important;
  }
}

@media only screen and (max-width: 485px) {
  .smm .CustomSubbanner img {
    width: 350px !important;
    bottom: -105px !important;
  }
  .smm .CustomSubbanner {
    margin-bottom: 150px !important;
  }
}
